* {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
}

body {
  color: #90ee90;
  perspective: 400px;
  background-color: #000;
}

#app {
  text-transform: uppercase;
  text-shadow: 0 0 3px;
  width: 920px;
  height: 500px;
  transform-style: preserve-3d;
  background: #000 radial-gradient(#0105, #010);
  border: 4px double green;
  margin: 1em auto;
  padding: 1em;
  font-family: VT323, monospace;
  position: relative;
  overflow: hidden;
  transform: translateY(-10px)rotateX(2deg);
}

#app:before {
  content: "";
  mix-blend-mode: color-dodge;
  opacity: .4;
  z-index: -1;
  background: url("yutani_1.6b276f15.png") 0 100% no-repeat;
  position: absolute;
  inset: 0;
}

#app #static {
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: 1000;
  pointer-events: none;
  mix-blend-mode: screen;
  display: block;
  position: absolute;
  inset: 0;
}

#app h2 {
  margin: 0;
}

#app button {
  cursor: pointer;
  text-shadow: inherit;
  text-transform: inherit;
  font-family: inherit;
  line-height: 1;
}

#app button * {
  cursor: inherit;
}

#app button[disabled] {
  cursor: not-allowed;
}

#app .flex-row {
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  display: flex;
}

#app .flex-column {
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  display: flex;
}

#app #fs {
  color: green;
  z-index: 99999;
  background-color: #000;
  border: 1px dotted green;
  padding: .1em;
  font-size: 8px;
  position: absolute;
  top: 10px;
  right: 10px;
}

#app .action-item {
  appearance: none;
  color: #0ff;
  width: 200px;
  text-align: left;
  cursor: pointer;
  background: #000;
  border: 1px solid;
  padding: .3em;
  line-height: 1;
  transition: box-shadow .3s;
}

#app .action-item:hover {
  box-shadow: inset 0 0 1em;
}

#app .action-item[data-type="catch"] {
  color: #fff;
}

#app .action-item[data-type="pickup"], #app .action-item[data-type="drop"], #app .action-item[data-type="swap"] {
  color: #66f;
}

#app .action-item .action-icon {
  margin-right: .3em;
  font-size: 1.3em;
}

#app .action-item[disabled] {
  color: #666;
}

#app .action-item[disabled]:hover {
  box-shadow: none;
}

#app .fade-in {
  opacity: 0;
  animation: 1s forwards fadeIn;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

#app .progress-bar {
  height: 1em;
  width: 100%;
  background: #020 linear-gradient(90deg, #0005, #0005 50%, #0000 50% 100%) 0 0 / 2px;
  display: block;
  position: relative;
}

#app .progress-bar .progress-bar-inner {
  color: green;
  height: 100%;
  background-color: currentColor;
  transition: width .2s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 0 10px;
}

#app .action-progress {
  background-color: #022;
}

#app .action-progress .progress-bar-inner {
  color: #0ff;
}

#app #start-view {
  text-align: center;
  height: 400px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#app #start-view h1 {
  color: #0000;
  letter-spacing: .3em;
  margin-left: 1em;
  animation: 10s linear 13s forwards titleReveal1;
  position: relative;
  transform: scaleX(3);
}

#app #start-view h1:after {
  content: "A L I E N";
  color: #fff;
  filter: blur(10px);
  mix-blend-mode: plus-lighter;
  animation: 12s ease-out forwards titleReveal2, .3s infinite alternate titleFlicker;
  position: absolute;
  inset: 0;
  transform: scale(1.6, 4);
}

#app #start-view .start-button {
  text-align: center;
  margin-top: 2em;
}

@keyframes titleReveal1 {
  from {
    color: #0000;
  }

  to {
    color: #fff;
  }
}

@keyframes titleReveal2 {
  to {
    filter: blur(4px);
    transform: scale(1.05, 4);
  }
}

@keyframes titleFlicker {
  0% {
    opacity: .65;
  }

  10% {
    opacity: .62;
  }

  30% {
    opacity: .66;
  }

  40% {
    opacity: .65;
  }

  70% {
    opacity: .66;
  }

  80% {
    opacity: .62;
  }

  90% {
    opacity: .64;
  }

  100% {
    opacity: .66;
  }
}

#app #scenario-view {
  background: #010d;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2em;
  display: flex;
  position: absolute;
  inset: 2em;
}

#app #scenario-view h2 {
  color: #fff;
  font-size: 2em;
}

#app #scenario-view #scenario-view-selector {
  height: 200px;
  width: 400px;
  align-items: flex-start;
  margin-top: 2em;
}

#app #scenario-view #scenario-view-selector nav {
  margin-right: 2em;
}

#app #scenario-view #scenario-options {
  flex-shrink: 0;
  margin-right: 2em;
}

#app #scenario-view #scenario-options label {
  cursor: pointer;
  width: 100px;
  justify-content: space-between;
  margin-bottom: 1em;
  font-size: 1.4em;
}

#app #scenario-view #scenario-options label * {
  cursor: inherit;
}

#app #scenario-view #scenario-options label input {
  appearance: none;
  color: #0ff;
  width: 1em;
  height: 1em;
  background: #000;
  border: 1px solid;
  padding: .3em;
  line-height: 1;
  transition: box-shadow .3s;
}

#app #scenario-view #scenario-options label input:hover {
  box-shadow: inset 0 0 1em;
}

#app #scenario-view #scenario-options label input:checked {
  background-color: #0ff;
}

#app #scenario-view #scenario-options label input[disabled] {
  cursor: not-allowed;
  color: #666;
}

#app #scenario-view #scenario-options label input[disabled]:hover {
  box-shadow: none;
}

#app #scenario-view #scenario-details {
  text-align: left;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
}

#app #scenario-view #scenario-details p {
  margin-top: 0;
  margin-bottom: 1em;
}

#app #scenario-view #scenario-details p:before {
  content: "> ";
}

#app #game-panel {
  color: green;
  text-align: left;
  justify-content: space-between;
  margin-bottom: .3em;
  font-size: 1.6em;
  line-height: 1;
}

#app .indicator:not([data-count="0"]) {
  color: #ff0;
  animation: .6s .6s forwards doorColor;
  position: relative;
}

#app .indicator:not([data-count="0"]) span {
  line-height: 1;
}

#app .indicator:not([data-count="0"]):before {
  content: "";
  width: 30px;
  height: 30px;
  color: #ff0;
  opacity: .6;
  background-color: currentColor;
  animation: .6s forwards doorPing;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  box-shadow: 0 0 10px;
}

@keyframes doorColor {
  from {
    color: #ff0;
  }

  to {
    color: green;
  }
}

@keyframes doorPing {
  from {
    opacity: .6;
  }

  to {
    opacity: 0;
  }
}

#app .alert-icons {
  height: 35px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#app .alert-icons span {
  font-size: 1em;
}

#app .sees-tracker-icon, #app .sees-cat-hiss-icon {
  color: #ff0;
  animation: .2s infinite alternate flashNotify;
}

#app .sees-cat-icon {
  animation: .4s infinite alternate flashNotify;
}

#app .sees-alien-icon {
  color: red;
  animation: .2s infinite alternate flashNotify;
  font-size: 1.2em !important;
}

@keyframes flashNotify {
  from {
    opacity: .4;
  }

  to {
    opacity: 1;
  }
}

#app #crew-panel {
  width: 240px;
  flex-grow: 0;
  flex-shrink: 0;
}

#app #crew-panel .char-status {
  color: green;
  width: 100%;
  text-align: left;
  background: none;
  border: 2px solid #0000;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-start;
  padding: .1em;
  transition: border-color .3s;
  display: flex;
}

#app #crew-panel .char-status .char-stats {
  height: 35px;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: space-between;
  margin-right: .3em;
  display: flex;
}

#app #crew-panel .char-status .char-stats .char-health-bar, #app #crew-panel .char-status .char-stats .char-will-bar {
  width: 40px;
}

#app #crew-panel .char-status .char-img {
  width: 35px;
  height: 35px;
  background-blend-mode: multiply;
  image-rendering: pixelated;
  background: green url("AlienTempCrew.47848e51.jpg") 0 0 / 100%;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: .3em;
}

#app #crew-panel .char-status[data-name="Dallas"] .char-img {
  background-position-y: -149px;
}

#app #crew-panel .char-status[data-name="Kane"] .char-img {
  background-position-y: -51px;
}

#app #crew-panel .char-status[data-name="Ripley"] .char-img {
  background-position-y: -238px;
}

#app #crew-panel .char-status[data-name="Ash"] .char-img {
  background-position-y: -100px;
}

#app #crew-panel .char-status[data-name="Lambert"] .char-img {
  background-position-y: -7px;
}

#app #crew-panel .char-status[data-name="Parker"] .char-img {
  background-position-y: -196px;
}

#app #crew-panel .char-status[data-name="Brett"] .char-img {
  background-position-y: -288px;
}

#app #crew-panel .char-status h3 {
  color: #90ee90;
  margin: 0;
}

#app #crew-panel .char-status:not([disabled]):hover {
  border-color: green;
}

#app #crew-panel .char-status[data-active="true"] {
  border-color: #90ee90;
  border-right-width: 1em;
}

#app #crew-panel .char-status[data-active="true"] .char-img {
  background-blend-mode: hard-light;
}

#app #crew-panel .char-status[data-health="0"], #app #crew-panel .char-status[data-health="0"] h3 {
  color: #a11;
}

#app #crew-panel .char-status[data-health="0"]:hover {
  cursor: not-allowed;
  border-color: #0000;
}

#app #crew-panel .char-status[data-health="0"] .char-health-bar, #app #crew-panel .char-status[data-health="0"] .char-will-bar {
  background-color: #200;
}

#app #crew-panel .char-status[data-health="0"] .char-img {
  opacity: .5;
  background-color: #a11;
}

#app #log-panel {
  height: 120px;
  background-color: #0009;
  margin-top: .5em;
  padding: .2em;
  font-size: .9em;
}

#app #log-panel p {
  color: green;
  margin: 0 0 .1em;
  line-height: 1;
}

#app #log-panel p:before {
  content: ">";
}

#app #log-panel p[data-type="notify"] {
  color: #0ff;
}

#app #log-panel p[data-type="warning"] {
  color: #ff0;
}

#app #log-panel p[data-type="danger"] {
  color: pink;
}

#app #log-panel p[data-type="critical"] {
  color: red;
}

#app #active-crew-panel {
  margin-bottom: 1em;
}

#app #active-crew-panel .char-img {
  width: 60px;
  height: 73px;
  background-blend-mode: hard-light;
  image-rendering: pixelated;
  background: green url("AlienTempCrew.47848e51.jpg") 0 0 / 100%;
  border: 1px solid green;
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: .3em;
  margin-right: .3em;
}

#app #active-crew-panel [data-vent-open="true"] {
  color: #ff0;
}

#app #active-crew-panel[data-name="Dallas"] .char-img {
  background-position-y: -65%;
}

#app #active-crew-panel[data-name="Kane"] .char-img {
  background-position-y: -98%;
}

#app #active-crew-panel[data-name="Ripley"] .char-img {
  background-position-y: -32%;
}

#app #active-crew-panel[data-name="Ash"] .char-img {
  background-position-y: -81.5%;
}

#app #active-crew-panel[data-name="Lambert"] .char-img {
  background-position-y: 0%;
}

#app #active-crew-panel[data-name="Parker"] .char-img {
  background-position-y: -48.5%;
}

#app #active-crew-panel[data-name="Brett"] .char-img {
  background-position-y: -15%;
}

#app #active-crew-panel .action-progress {
  width: 100%;
}

#app #active-crew-panel .char-inv-slot {
  color: green;
  border: 1px dotted;
  justify-content: space-between;
  margin-top: .3em;
  padding: .2em .3em;
}

#app #active-crew-panel .char-inv-slot .inv-item-equip {
  background: currentColor;
  margin-right: .3em;
  padding: 0 .3em;
}

#app #active-crew-panel .char-inv-slot .inv-item-equip strong {
  color: #000;
}

#app #active-crew-panel .char-inv-slot[data-item] {
  color: #90ee90;
}

#app #active-crew-panel .char-inv-slot .sees-tracker-icon {
  font-size: .9em;
}

#app #map-panel .room-status[data-active="true"] {
  background-color: #232;
}

#app #map-panel .room-status .room-members [data-active="true"] {
  color: green;
}

#app #map-display {
  width: 400px;
  height: 400px;
  background: url("AlienTempMap.2a11a2e5.png") 0 0 no-repeat;
  border: 3px double green;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
}

#app #map-display:before {
  content: "";
  z-index: 10;
  background: radial-gradient(#0108, #000a);
  position: absolute;
  inset: 0;
}

#app #map-display[data-alien-alert="true"] {
  border-color: red;
}

#app #map-display[data-alien-alert="true"]:after {
  content: "";
  z-index: 20;
  mix-blend-mode: color;
  background-color: red;
  animation: .6s linear infinite activeLocation;
  position: absolute;
  inset: 0;
}

#app #map-display #map-alien-alert {
  text-align: center;
  z-index: 30;
  color: #000;
  background-color: red;
  padding: .2em;
  font-size: 1.2em;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

#app #map-display [data-location=""] {
  display: none;
  top: 0;
  left: 0;
}

#app #map-display [data-location="livingQuarters"] {
  top: 130px;
  left: 130px;
}

#app #map-display [data-location="recreation"] {
  top: 230px;
  left: 130px;
}

#app #map-display [data-location="airlock1"] {
  top: 100px;
  left: 220px;
}

#app #map-display [data-location="mess"] {
  top: 180px;
  left: 210px;
}

#app #map-display [data-location="airlock2"] {
  top: 270px;
  left: 220px;
}

#app #map-display [data-location="corridor6"] {
  top: 180px;
  left: 275px;
}

#app #map-display [data-location="stores2"] {
  top: 100px;
  left: 310px;
}

#app #map-display [data-location="stores3"] {
  top: 270px;
  left: 310px;
}

#app #map-display [data-location="computer"] {
  top: 180px;
  left: 323px;
}

#app #map-display [data-location="commandCenter"] {
  top: 180px;
  left: 50px;
}

#app #map-display [data-location="infirmary"] {
  top: 120px;
  left: 130px;
}

#app #map-display [data-location="corridor1"] {
  top: 183px;
  left: 100px;
}

#app #map-display [data-location="laboratory"] {
  top: 250px;
  left: 130px;
}

#app #map-display [data-location="corridor2"] {
  top: 180px;
  left: 177px;
}

#app #map-display [data-location="infStores"] {
  top: 47px;
  left: 233px;
}

#app #map-display [data-location="corridor3"] {
  top: 107px;
  left: 210px;
}

#app #map-display [data-location="cryoVault"] {
  top: 185px;
  left: 215px;
}

#app #map-display [data-location="corridor5"] {
  top: 262px;
  left: 210px;
}

#app #map-display [data-location="labStores"] {
  top: 320px;
  left: 233px;
}

#app #map-display [data-location="armory"] {
  top: 60px;
  left: 274px;
}

#app #map-display [data-location="corridor4"] {
  top: 190px;
  left: 274px;
}

#app #map-display [data-location="stores1"] {
  top: 310px;
  left: 274px;
}

#app #map-display [data-location="engine1"] {
  top: 70px;
  left: 330px;
}

#app #map-display [data-location="engine2"] {
  top: 180px;
  left: 330px;
}

#app #map-display [data-location="engine3"] {
  top: 290px;
  left: 330px;
}

#app #map-display [data-location="shuttleStorage"] {
  top: 80px;
  left: 130px;
}

#app #map-display [data-location="shuttleBay"] {
  top: 160px;
  left: 120px;
}

#app #map-display [data-location="engineering"] {
  top: 260px;
  left: 130px;
}

#app #map-display [data-location="engStorage"] {
  top: 315px;
  left: 130px;
}

#app #map-display [data-location="cargoPod1"] {
  top: 80px;
  left: 200px;
}

#app #map-display [data-location="cargoPod2"] {
  top: 180px;
  left: 200px;
}

#app #map-display [data-location="cargoPod3"] {
  top: 320px;
  left: 200px;
}

#app #map-display [data-location="corridor7"] {
  top: 185px;
  left: 250px;
}

#app #map-display [data-location="lifeSupport"] {
  top: 185px;
  left: 330px;
}

#app #map-display #active-member-location {
  z-index: 20;
  width: 20px;
  height: 20px;
  background-color: #90ee90;
  animation: 1s linear infinite activeLocation;
  position: absolute;
  box-shadow: 0 0 10px;
}

#app #map-display #active-member-location:not([data-location]) {
  display: none;
}

#app #map-display #target-member-location {
  z-index: 20;
  width: 20px;
  height: 20px;
  color: #0ff;
  border: 4px double;
  transition: top .1s, left .1s;
  animation: .5s steps(3, end) infinite targetLocation;
  position: absolute;
  box-shadow: 0 0 5px, inset 0 0 5px;
}

#app #map-display #target-member-location:not([data-location]) {
  display: none;
}

@keyframes activeLocation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes targetLocation {
  0% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(.5);
  }
}

#app #map-display[data-floor="lower"] {
  background-size: 390%;
}

#app #map-display[data-floor="middle"] {
  background-position-x: -445px;
  background-size: 390%;
}

#app #map-display[data-floor="upper"] {
  background-position-x: -873px;
  background-size: 390%;
}

#app #actions-panel {
  height: 180px;
  flex-grow: 0;
  flex-shrink: 0;
}

#app .scroll-panel {
  scroll-behavior: smooth;
  padding-right: .3em;
  overflow-y: scroll;
}

#app .scroll-panel::-webkit-scrollbar {
  width: .3em;
  height: .3em;
  background-color: #0000;
}

#app .scroll-panel::-webkit-scrollbar-thumb {
  background-color: #033;
}

/*# sourceMappingURL=index.0cfb6f90.css.map */
