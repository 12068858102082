* {
  box-sizing: border-box;
  user-select: none;
  cursor: default;
}

body {
  background-color: black;
  color: lightgreen;
  perspective: 400px;
}

#app {
  // Generics
  font-family: 'VT323', monospace;
  text-transform: uppercase;
  text-shadow: 0 0 3px currentColor;
  padding: 1em;
  background: black radial-gradient(#0105, #010);
  border: 4px double green;
  margin: 1em auto;
  width: 920px;
  height: 500px;
  position: relative;
  transform-style: preserve-3d;
  transform: translateY(-10px) rotateX(2deg);
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("./assets/gfx/yutani_1.png") no-repeat bottom left;
    mix-blend-mode: color-dodge;
    opacity: 0.4;
    z-index: -1;
  }

  #static {
    position: absolute;
    display: block;
    object-fit: cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    pointer-events: none;
    mix-blend-mode: screen;
  }

  h2 {
    margin: 0;
  }

  button {
    cursor: pointer;
    font-family: inherit;
    text-shadow: inherit;
    text-transform: inherit;
    line-height: 1;

    * {
      cursor: inherit;
    }

    &[disabled] {
      cursor: not-allowed;
    }
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
  }

  // Components

  #fs {
    font-size: 8px;
    background-color: black;
    color: green;
    border: 1px dotted green;
    padding: 0.1em;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 99999;
  }

  .action-item {
    appearance: none;
    background: black;
    color: cyan;
    border: 1px solid;
    width: 200px;
    padding: 0.3em;
    transition: box-shadow 0.3s ease;
    text-align: left;
    line-height: 1;
    cursor: pointer;

    &:hover {
      box-shadow: inset 0 0 1em currentColor;
    }

    &[data-type="catch"] {
      color: white;
    }

    &[data-type="pickup"],
    &[data-type="drop"],
    &[data-type="swap"]{
      color: #66f;
    }

    .action-icon {
      margin-right: 0.3em;
      font-size: 1.3em;
    }

    &[disabled] {
      color: #666;
      &:hover {
        box-shadow: none;
      }
    }
  }

  .fade-in {
    opacity: 0;
    animation: fadeIn 1s ease forwards;
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }

  .progress-bar {
    display: block;
    background: linear-gradient(90deg, #0005, #0005 50%, #0000 50%, #0000 100%);
    background-size: 2px;
    background-color: #020;
    position: relative;
    height: 1em;
    width: 100%;

    .progress-bar-inner {
      position: absolute;
      color: green;
      background-color: currentColor;
      box-shadow: 0 0 10px currentColor;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      transition: width 0.2s ease;
    }
  }

  .action-progress {
    background-color: #022;

    .progress-bar-inner {
      color: cyan;
    }
  }

  // Start

  #start-view {
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 400px;
    align-items: center;
    justify-content: center;

    h1 {
      color: transparent;
      position: relative;
      transform: scaleX(3);
      animation: titleReveal1 10s linear forwards;
      animation-delay: 13s;
      letter-spacing: 0.3em;
      margin-left: 1em;

      &::after {
        content: 'A L I E N';
        color: white;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        filter: blur(10px);
        transform: scale(1.6, 4);
        animation: titleReveal2 12s ease-out forwards,
          titleFlicker 0.3s alternate infinite;
        mix-blend-mode: plus-lighter;
      }
    }

    .start-button {
      margin-top: 2em;
      text-align: center;
    }
  }

  @keyframes titleReveal1 {
    from {
      color: transparent;
    }
    to {
      color: white;
    }
  }

  @keyframes titleReveal2 {
    to {
      filter: blur(4px);
      transform: scale(1.05, 4);
    }
  }

  @keyframes titleFlicker {
    0% {
      opacity: 0.65;
    }
    10% {
      opacity: 0.62;
    }
    30% {
      opacity: 0.66;
    }
    40% {
      opacity: 0.65;
    }
    70% {
      opacity: 0.66;
    }
    80% {
      opacity: 0.62;
    }
    90% {
      opacity: 0.64;
    }
    100% {
      opacity: 0.66;
    }
  }

  #scenario-view {
    position: absolute;
    top: 2em;
    left: 2em;
    right: 2em;
    bottom: 2em;
    padding: 2em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #010d;

    h2 {
      color: white;
      font-size: 2em;
    }

    #scenario-view-selector {
      height: 200px;
      width: 400px;
      align-items: flex-start;
      margin-top: 2em;

      nav {
        margin-right: 2em;
      }
    }

    #scenario-options {
      flex-shrink: 0;
      margin-right: 2em;

      label {
        cursor: pointer;
        width: 100px;
        justify-content: space-between;
        margin-bottom: 1em;
        font-size: 1.4em;

        * {
          cursor: inherit;
        }

        input {
          appearance: none;
          background: black;
          color: cyan;
          border: 1px solid;
          width: 1em;
          height: 1em;
          padding: 0.3em;
          transition: box-shadow 0.3s ease;
          line-height: 1;

          &:hover {
            box-shadow: inset 0 0 1em currentColor;
          }

          &:checked {
            background-color: cyan;
          }

          &[disabled] {
            cursor: not-allowed;
            color: #666;

            &:hover {
              box-shadow: none;
            }
          }
        }
      }
    }

    #scenario-details {
      flex-grow: 1;
      flex-shrink: 0;
      text-align: left;
      height: 100%;
      width: 100%;

      p {
        margin-top: 0;
        margin-bottom: 1em;

        &::before {
          content: '> ';
        }
      }
    }
  }

  // Game panel

  #game-panel {
    margin-bottom: 0.3em;
    font-size: 1.6em;
    line-height: 1;
    color: green;
    text-align: left;
    justify-content: space-between;
  }

  .indicator {
    &:not([data-count="0"]) {
      color: yellow;
      position: relative;
      animation: doorColor 0.6s ease;
      animation-fill-mode: forwards;
      animation-delay: 0.6s;

      span {
        line-height: 1;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30px;
        height: 30px;
        color: yellow;
        background-color: currentColor;
        box-shadow: 0 0 10px currentColor;
        opacity: 0.6;
        transform: translate(-50%, -60%);
        animation: doorPing 0.6s ease;
        animation-fill-mode: forwards;
      }
    }
  }

  @keyframes doorColor {
    from {
      color: yellow;
    }
    to {
      color: green;
    }
  }

  @keyframes doorPing {
    from {
      opacity: 0.6;
    }
    to {
      opacity: 0;
    }
  }

  // Character panel
  .alert-icons {
    display: flex;
    height: 35px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      font-size: 1em;
    }
  }

  .sees-tracker-icon,
  .sees-cat-hiss-icon {
    color: yellow;
    animation: flashNotify 0.2s ease alternate infinite;
  }

  .sees-cat-icon {
    animation: flashNotify 0.4s ease alternate infinite;
  }

  .sees-alien-icon {
    color: red;
    font-size: 1.2em!important;
    animation: flashNotify 0.2s ease alternate infinite;
  }

  @keyframes flashNotify {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }

  #crew-panel {
    width: 240px;
    flex-grow: 0;
    flex-shrink: 0;

    .char-status {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: stretch;
      background: transparent;
      color: green;
      width: 100%;
      text-align: left;
      border: 2px solid transparent;
      padding: 0.1em;
      transition: border-color 0.3s ease;

      .char-stats {
        flex-grow: 0;
        flex-shrink: 0;
        margin-right: 0.3em;
        display: flex;
        height: 35px;
        flex-direction: column;
        justify-content: space-between;

        .char-health-bar, .char-will-bar {
          width: 40px;
        }
      }

      .char-img {
        flex-grow: 0;
        flex-shrink: 0;
        width: 35px;
        height: 35px;
        background: green;
        margin-right: 0.3em;
        background-image: url("./assets/gfx/AlienTempCrew.jpg");
        background-size: 100%;
        background-blend-mode: multiply;
        image-rendering: pixelated;
      }

      &[data-name="Dallas"] .char-img {
        background-position-y: -149px;
      }

      &[data-name="Kane"] .char-img {
        background-position-y: -51px;
      }

      &[data-name="Ripley"] .char-img {
        background-position-y: -238px;
      }

      &[data-name="Ash"] .char-img {
        background-position-y: -100px;
      }

      &[data-name="Lambert"] .char-img {
        background-position-y: -7px;
      }

      &[data-name="Parker"] .char-img {
        background-position-y: -196px;
      }

      &[data-name="Brett"] .char-img {
        background-position-y: -288px;
      }

      h3 {
        margin: 0;
        color: lightgreen;
      }

      &:not([disabled]):hover {
        border-color: green;
      }

      &[data-active="true"] {
        border-color: lightgreen;
        border-right-width: 1em;

        .char-img {
          background-blend-mode: hard-light;
        }
      }

      &[data-health="0"] {
        color: #a11;

        h3 {
          color: #a11;
        }

        &:hover {
          border-color: transparent;
          cursor: not-allowed;
        }

        .char-health-bar, .char-will-bar {
          background-color: #200;
        }

        .char-img {
          background-color: #a11;
          opacity: 0.5;
        }
      }
    }
  }

  // Log panel

  #log-panel {
    height: 120px;
    background-color: #0009;
    padding: 0.2em;
    margin-top: 0.5em;
    font-size: 0.9em;

    p {
      margin: 0 0 0.1em 0;
      line-height: 1;
      color: green;

      &::before {
        content: '>';
      }

      &[data-type="notify"] {
        color: cyan;
      }

      &[data-type="warning"] {
        color: yellow;
      }

      &[data-type="danger"] {
        color: pink;
      }

      &[data-type="critical"] {
        color: red;
      }
    }
  }

  // Active crew panel

  #active-crew-panel {
    margin-bottom: 1em;

    .char-img {
      width: 60px;
      height: 73px;
      background-color: green;
      border: 1px solid green;
      margin-right: 0.6em;
      margin-bottom: 0.3em;
      flex-grow: 0;
      flex-shrink: 0;
      background: green;
      margin-right: 0.3em;
      background-image: url("./assets/gfx/AlienTempCrew.jpg");
      background-size: 100%;
      background-blend-mode: hard-light;
      image-rendering: pixelated;
    }

    [data-vent-open="true"] {
      color: yellow;
    }

    &[data-name="Dallas"] .char-img {
      background-position-y: -65%;
    }

    &[data-name="Kane"] .char-img {
      background-position-y: -98%;
    }

    &[data-name="Ripley"] .char-img {
      background-position-y: -32%;
    }

    &[data-name="Ash"] .char-img {
      background-position-y: -81.5%;
    }

    &[data-name="Lambert"] .char-img {
      background-position-y: -0%;
    }

    &[data-name="Parker"] .char-img {
      background-position-y: -48.5%;
    }

    &[data-name="Brett"] .char-img {
      background-position-y: -15%;
    }

    .action-progress {
      width: 100%;
    }

    .char-inv-slot {
      border: 1px dotted currentColor;
      margin-top: 0.3em;
      padding: 0.2em 0.3em;
      color: green;
      justify-content: space-between;

      .inv-item-equip {
        background: currentColor;
        padding: 0 0.3em;
        margin-right: 0.3em;
        strong {
          color: black;
        }
      }

      &[data-item] {
        color: lightgreen;
      }

      .sees-tracker-icon {
        font-size: 0.9em;
      }
    }
  }

  // Map panel

  #map-panel {

    .room-status {
      &[data-active="true"] {
        background-color: #232;
      }

      .room-members {
        [data-active="true"] {
          color: green;
        }
      }
    }
  }

  // Map display

  #map-display {
    flex-grow: 0;
    flex-shrink: 0;
    width: 400px;
    height: 400px;
    border: 3px double green;
    position: relative;
    background: url('./assets/gfx/AlienTempMap.png') no-repeat top left;

    &::before {
      content: '';
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: radial-gradient(#0108, #000a);
    }

    &[data-alien-alert="true"] {
      border-color: red;

      &::after {
        content: '';
        position: absolute;
        z-index: 20;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: red;
        mix-blend-mode: color;
        animation: activeLocation 0.6s linear infinite;
      }
    }

    #map-alien-alert {
      text-align: center;
      background-color: red;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 30;
      color: black;
      padding: 0.2em;
      font-size: 1.2em;
    }

    [data-location=""] {
      top: 0;
      left: 0;
      display: none;
    }

    // Upper deck

    [data-location="livingQuarters"] {
      top: 130px;
      left: 130px;
    }

    [data-location="recreation"] {
      top: 230px;
      left: 130px;
    }

    [data-location="airlock1"] {
      top: 100px;
      left: 220px;
    }

    [data-location="mess"] {
      top: 180px;
      left: 210px;
    }

    [data-location="airlock2"] {
      top: 270px;
      left: 220px;
    }

    [data-location="corridor6"] {
      top: 180px;
      left: 275px;
    }

    [data-location="stores2"] {
      top: 100px;
      left: 310px;
    }

    [data-location="stores3"] {
      top: 270px;
      left: 310px;
    }

    [data-location="computer"] {
      top: 180px;
      left: 323px;
    }

    // Middle deck

    [data-location="commandCenter"] {
      top: 180px;
      left: 50px;
    }

    [data-location="infirmary"] {
      top: 120px;
      left: 130px;
    }

    [data-location="corridor1"] {
      top: 183px;
      left: 100px;
    }

    [data-location="laboratory"] {
      top: 250px;
      left: 130px;
    }

    [data-location="corridor2"] {
      top: 180px;
      left: 177px;
    }

    [data-location="infStores"] {
      top: 47px;
      left: 233px;
    }

    [data-location="corridor3"] {
      top: 107px;
      left: 210px;
    }

    [data-location="cryoVault"] {
      top: 185px;
      left: 215px;
    }

    [data-location="corridor5"] {
      top: 262px;
      left: 210px;
    }

    [data-location="labStores"] {
      top: 320px;
      left: 233px;
    }

    [data-location="armory"] {
      top: 60px;
      left: 274px;
    }

    [data-location="corridor4"] {
      top: 190px;
      left: 274px;
    }

    [data-location="stores1"] {
      top: 310px;
      left: 274px;
    }

    [data-location="engine1"] {
      top: 70px;
      left: 330px;
    }

    [data-location="engine2"] {
      top: 180px;
      left: 330px;
    }

    [data-location="engine3"] {
      top: 290px;
      left: 330px;
    }

    // Lower deck

    [data-location="shuttleStorage"] {
      top: 80px;
      left: 130px;
    }

    [data-location="shuttleBay"] {
      top: 160px;
      left: 120px;
    }

    [data-location="engineering"] {
      top: 260px;
      left: 130px;
    }

    [data-location="engStorage"] {
      top: 315px;
      left: 130px;
    }

    [data-location="cargoPod1"] {
      top: 80px;
      left: 200px;
    }

    [data-location="cargoPod2"] {
      top: 180px;
      left: 200px;
    }

    [data-location="cargoPod3"] {
      top: 320px;
      left: 200px;
    }

    [data-location="corridor7"] {
      top: 185px;
      left: 250px;
    }

    [data-location="lifeSupport"] {
      top: 185px;
      left: 330px;
    }

    #active-member-location {
      position: absolute;
      z-index: 20;
      width: 20px;
      height: 20px;
      background-color: lightgreen;
      box-shadow: 0 0 10px;
      animation: activeLocation 1s linear infinite;

      &:not([data-location]) {
        display: none;
      }
    }

    #target-member-location {
      position: absolute;
      z-index: 20;
      width: 20px;
      height: 20px;
      color: cyan;
      border: 4px double currentColor;
      box-shadow: 0 0 5px currentColor, inset 0 0 5px currentColor;
      animation: targetLocation 0.5s steps(3, end) infinite;
      transition: top 0.1s ease, left 0.1s ease;

      &:not([data-location]) {
        display: none;
      }
    }

    @keyframes activeLocation {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    @keyframes targetLocation {
      0% {
        transform: scale(1.5);
      }
      100% {
        transform: scale(0.5);
      }
    }

    &[data-floor="lower"] {
      background-size: 390%;
    }

    &[data-floor="middle"] {
      background-position-x: -445px;
      background-size: 390%;
    }

    &[data-floor="upper"] {
      background-position-x: -873px;
      background-size: 390%;
    }
  }

  // Actions panel

  #actions-panel {
    flex-grow: 0;
    flex-shrink: 0;
    height: 180px;
  }

  .scroll-panel {
    overflow-y: scroll;
    scroll-behavior: smooth;
    padding-right: 0.3em;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 0.3em;
      height: 0.3em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #033;
    }
  }
}
